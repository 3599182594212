@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,100;1,400;1,500&display=swap");
body {
  font-family: "Poppins", sans-serif;
  background: #0c0c0c;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*==========================

NAVBAR STYLES 

============================*/
.main-nav {
  background: #1d1d1d;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 120px;
  padding: 30px 0px;
}
.nav-inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
}
.nav-inner img {
  width: 150px;
  object-fit: cover;
  margin-top: -50px;
}
.nav-links a {
  text-transform: capitalize;
  color: #fff;
  margin-right: 15px;
  text-decoration: none;
  font-size: 15px;
}

.nav-links a:hover {
  color: #4ea5d9;
  transition: all ease-in 0.3s;
}
.login-sign,
.home-login-sign {
  display: flex;
  align-items: center;
}
.home-login-sign {
  background: #1d1d1d;
  justify-content: center;
  padding: 15px 0px;
  display: none;
}
.home-login-sign .btn-login,
.home-login-sign .btn-signup {
  margin: 0px !important;
}
.home-login-sign .btn-login {
  margin-right: 40px !important;
}
.login-sign a,
.home-login-sign a {
  display: flex;
  padding: 15px 25px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
}
.home-login-sign a {
  margin-right: 10px;
}
.home-login-sign a:nth-child(2) {
  margin-left: 20px;
}
.login-sign a svg,
.home-login-sign a svg {
  height: 25px;
  margin-right: 10px;
}
.btn-login {
  color: #4ea5d9;
  border: 1px solid #4ea5d9;
  margin-right: 15px;
}
.btn-signup {
  color: #fff;
  background: #4ea5d9;
  margin-left: 10px;
}
.bar {
  display: none;
  background: #4ea5d9;
  padding: 5px 10px;
  border-radius: 5px;
}

.bar svg {
  height: 40px;
  color: #fff;
}
.forlinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
/* ======================

LIVE READINGS

==========================*/
.live {
  background: #1d1d1d;
}
.readings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 20px 0px;
  border-top: 1px solid #4ea5d9;
  border-bottom: 1px solid #4ea5d9;
}
.live-box p {
  color: #fff;
  margin-bottom: 5px !important;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.live-box:last-child p {
  color: #4ea5d9 !important;
}
.live-box span {
  color: #9d9d8f;
  text-align: center;
}

/*===============================

CURRENCY SLIDER

================================*/
.curslider {
  height: 50px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.curslider-track {
  display: flex;
  gap: 10px;
  width: calc(400px * 12);
  animation: scroll 40s linear infinite;
  background: #fff;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 6));
  }
}
.curslider-track:hover {
  animation-play-state: paused;
}
.curslide {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  gap: 20px;
  transition: all ease-in 0.1s;
  text-decoration: none;
}
.curslide img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50%;
}
.slide-text {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0c0c0c;
}
.slide-text .red {
  color: red;
  border-right: 1px solid #aaaaaa;
  padding-right: 10px;
}
.slide-text .green {
  color: #0ba026;
  padding-right: 10px;
  border-right: 1px solid #aaaaaa;
}
.curslide:hover {
  background: #f0f3fa;
  cursor: pointer;
}
.slide-name p {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: bold;
  color: #0c0c0c;
}
.slide-name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.curslider::before,
.curslider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}
.curslider::before {
  top: 0;
  left: 0;
}
.curslider::after {
  top: 0;
  right: 0;
  transform: rotateZ(180deg);
}
.main-slide {
  padding: 20px 0px;
}

/*==============================

SHOWCASE

===============================*/
.arrow {
  height: 40px;
  color: #fff;
}
.slick-arrow::before {
  display: none;
}
.slide-1,
.slide-2 {
  position: relative;
  background: url("https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/Stock-Market-.jpg");
  height: 420px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.slide-2 {
  background: url("https://images.unsplash.com/photo-1535320903710-d993d3d77d29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHRyYWRpbmclMjBjaGFydHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=600&q=60");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.slide-1 > div,
.slide-2 > div {
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.slide-1 h1,
.slide-2 h1 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
  font-size: 50px !important;
}

.slide-1 button,
.slide-2 button {
  margin-top: 30px;
  padding: 10px 30px;
  text-transform: capitalize;
  background: none;
  border: 1px solid #4ea5d9;
  color: #4ea5d9;
  transition: all 0.5s;
}
.slide-1 button:hover,
.slide-2 button:hover {
  background: #4ea5d9;
  color: #fff;
}
.slide-1 h1 span,
.slide-2 h1 span {
  color: #4ea5d9;
}

.slide-1::before,
.slide-2::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.slide-contain {
  max-width: 1240px;
  margin: 0 auto;
}
/* ============================

STEPS

================================*/
.steps-inner {
  background-color: #1d1d1d;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 40px 40px;
  margin-top: -50px;
  position: relative;
  z-index: 80;
  margin-bottom: 20px;
}
.minor {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  color: #fff;
  text-transform: capitalize;
}
.minor-text {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px !important;
}
.minor-sub {
  font-size: 15px;
  margin-bottom: 0px !important;
}
/* ==============================

ABOUT SECTION

=================================*/
.about-title {
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
  color: #fff;
  font-size: 60px !important;
  font-weight: bold;
  margin-bottom: 20px;
}
.about-title span {
  color: #4ea5d9;
}
.about-subtext {
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  padding: 0px 40px;
}
.about-subtext p {
  position: relative;
  background: #0c0c0c;
}
.about-subtext::before {
  content: "";
  height: 2px;
  width: 100%;
  background: #4ea5d9;
  position: absolute;
  left: 0;
  top: 50%;
}
.main-about {
  display: flex;
  gap: 70px;
  padding: 30px 90px;
  color: #fff;
}
.main-about-heading {
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  font-weight: 700;
}
.main-about p {
  color: #aaaaaa;
}
.about-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin: 30px 0px;
}
.about-tab h3 {
  font-size: 19px !important;
  cursor: pointer;
  transition: ease-in-out 0.3s all;
}
.about-tab h3:nth-child(2),
.about-tab h3:nth-child(3) {
  border-left: 1px solid #aaaaaa;
  padding: 0px 10px;
}
.about-tab h3.active {
  color: #4ea5d9;
}
.tab-txt {
  margin: 20px 0px;
}
.btn-abt {
  color: #fff;
  padding: 10px 25px;
  background: #4ea5d9;
  text-transform: capitalize;
  border-radius: 10px;
  border: none;
  margin: 40px 0px;
}
/*==============================

INTRO AKA VIDEO SECTION

=================================*/
.intro-minor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  padding: 15px;
}

.intro-minor h5 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
.intro-minor p {
  color: #aaaaaa;
  font-size: 15px;
}
.intro-minor > div {
  width: 30%;
  height: 30%;
}
.intro-minor img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.intro-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 50px 50px;
}
.intro {
  background: #1d1d1d;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 2fr 1.5fr;
}

.vid svg {
  height: 100px;
  color: #4ea5d9;
  border: 3px solid #fff;
  padding: 20px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
}
.vid svg:hover {
  background-color: #fff;
}
.vid {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("https://images.pexels.com/photos/14902677/pexels-photo-14902677.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.vid::before {
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.overlay-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 40px;
}
.overlay-close svg {
  height: 40px;
  color: #fff;
  cursor: pointer;
}
.overlay iframe {
  width: 100%;
  padding: 40px;
  height: 600px;
}
/* ==============================

PACKAGES SECTION

=================================*/
.packages {
  margin-bottom: 30px;
}

.buy-sell {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: capitalize;
}
.buy-inner p {
  margin-bottom: 0px !important;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
  z-index: 4;
}
.buy-inner {
  display: flex;
  justify-content: space-between;
  border: 1px solid #aaaaaa;
  width: 250px;
  position: relative;
}
.buy-active {
  background: #4ea5d9;
  transition: all 0.5s ease-in-out;
}
.buy-inner span {
  width: 125px;
  background-color: #4ea5d9;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
}
.buy-inner span.isAct {
  transform: translateX(125px);
  transition: 0.5s ease-in-out;
}
.buy-inner span.isActnt {
  transform: translateX(0px);
  transition: 0.5s ease-in-out;
}
.packs {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}
.pack {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #1d1d1d;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.pack h5 {
  font-weight: 600;
  font-size: 20px !important;
  margin-bottom: 30px !important;
}
.pack h1 {
  font-weight: 500;
  font-size: 50px !important;
  margin: 30px 0px !important;
}
.pack h5,
.pack p {
  letter-spacing: 1px;
  text-transform: uppercase;
}
.pack a {
  color: #fff;
  background: #4ea5d9;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 5px;
}
.new-plan svg{
  height: 20px;
}
.new-plan{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 15px;
  border-bottom: 2px solid #4ea5d9;
  padding-bottom: 10px;
  width: 100%;
}
.new-plan:last-child{
  border-bottom: 0px;
}
.new-plan p{
  font-size: 10px;
  margin-bottom: 0px;
}
/* ===============================

LOGIN SIGN UP

==============================*/
.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
}
.login-box form .spinLink {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}
.login-box .spinLink:hover {
  background: #4ea5d9;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #4ea5d9 0 0 25px #4ea5d9 0 0 50px #4ea5d9 0 100px #4ea5d9;
}
.login-box .spinLink span {
  position: absolute;
  display: block;
}
.login-box .spinLink span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #4ea5d9);
  animation: btn-anim1 1s linear infinite;
}
.login-box .spinLink span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #4ea5d9);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}
.login-box .spinLink span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(180deg, transparent, #4ea5d9);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}
.login-box .spinLink span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #4ea5d9);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}
.login-box .user-box input {
  width: 100%;
  padding: 10px 0px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}
.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #4ea5d9;
  font-size: 12px;
}
.login-box .user-box {
  position: relative;
}
.login-box {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  width: 500px;
  padding: 40px;
  /* transform: translate(-50%, -50%); */
  background: #0c0c0c;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
.login-main,
.reg-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.reg-main {
  height: 100% !important;
  padding: 40px 0px;
}
.remeber {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.remeber a {
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
}
.remeber label {
  text-transform: capitalize;
  margin-left: 10px;
  color: #fff !important;
  font-size: 16px !important;
}
.or {
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
}
.spinLink.google {
  margin-top: 10px !important;
  width: 100%;
  text-align: center;
}
.spinLink.google img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.dont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  color: #fff;
  text-transform: capitalize;
}
.dont p {
  margin-bottom: 0px !important;
}
.dont a {
  color: #fff;
  font-weight: 600;
}
@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}
@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
.copy {
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  margin-top: 30px;
}
.optn {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 25px;
}
.optn label {
  color: #fff;
  text-transform: capitalize;
  font-size: 17px;
  transition: 0.5s;
  margin-bottom: 10px;
}
.optn select {
  background: none;
  color: #aaaaaa;
  border: none;
  border-bottom: 1px solid #fff;
  padding: 10px;
  padding-left: 0px;
  text-transform: capitalize;
}
.optn select:focus {
  outline: none;
}
.optn select:focus ~ label {
  color: #4ea5d9;
  font-size: 14px;
  transition: all ease-in-out 0.5s;
}
/*================================

CALCULATOR STYLES 

==================================*/
.calculator {
  position: relative;
  height: 250px;
  margin-top: 150px;
  margin-bottom: 200px;
  background: url("https://www.vbitltd.com/images/backgrounds/bg-calculator.jpg");
  background-attachment: fixed;
}
.calculator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}
.calculator-body {
  background: #1d1d1d;
  position: absolute;
  top: -55px;
  right: 60px;
  left: 60px;
  color: #fff;
  height: fit-content;
  padding: 35px 0px;
}
.calculator-body .about-title {
  font-size: 40px !important;
}
.calculator-body p {
  text-align: center;
  margin-bottom: 40px;
}
.cal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cal-body input {
  background: #0c0c0c;
  color: #fff;
  border: none;
  padding: 11px 20px;
}
.cal-body input:focus {
  outline: none;
}
.equal {
  margin: 0px 30px;
  background: transparent !important;
}
.cal-body span,
.cal-body select option {
  background: #4ea5d9;
}
.btc-logo {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}
.cal-body select {
  background: none;
  border: none;
  color: #fff;
  text-transform: uppercase;
  width: 110px !important;
}
.cal-body select:focus {
  outline: none;
}
.btc {
  display: flex;
  align-items: center;
}
.btc span {
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.btc-sub {
  text-align: center;
  color: #9d9d8f;
  text-transform: capitalize;
  margin-top: 30px;
}
/* =============================

EXPERTS STYLING

=================================*/
.team-member {
  background: #1d1d1d;
  color: #fff;
  transition: all ease-in-out 0.3s;
  position: relative;
}
.team-member:hover {
  background: #4ea5d9;
}
.team-member:hover .team-info p {
  color: #fff;
}
.team-member img {
  width: 100%;
  border-bottom: 3px solid #4ea5d9;
}
.team-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 10px;
}
.team-info h4 {
  text-transform: uppercase;
}
.team-info p {
  transition: all ease-in-out 0.3s;
  text-transform: capitalize;
  margin-bottom: 0px !important;
  color: #9d9d8f;
}
.expert-inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}
.team-socials {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.team-socials a {
  height: 50px;
  width: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  margin-right: 15px;
  transition: all ease-in 0.2s;
  opacity: 0;
}
.team-socials a:hover {
  color: #4ea5d9;
}
.team-member:hover .team-socials a {
  opacity: 1;
}
.experts {
  margin-bottom: 40px;
}
/* ==============================

CEO SPEACH SECTION STYLES

=================================*/
.ceo {
  background: #1d1d1d;
  display: flex;
  align-items: center;
  height: 80vh;
}
.quote {
  background: url("https://www.vbitltd.com/images/backgrounds/bg-quote.jpg");
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: relative;
  color: #fff;
  padding-top: 100px;
}
.quote::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  content: "";
}
.quote p {
  position: relative;
}
.quote-text {
  font-style: italic;
  position: relative;
  padding: 40px 80px;
  font-weight: 300;
  margin-top: 30px;
}
.quote-text::before {
  content: "\201C";
  position: absolute;
  top: 0px;
  left: 10px;
  color: #4ea5d9;
  display: block;
  font-size: 80px;
}
.quote-text::after {
  content: "\201C";
  position: absolute;
  bottom: -40px;
  right: 30px;
  color: #4ea5d9;
  display: block;
  font-size: 80px;
}
.ceo-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ceo-name img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  position: relative;
  margin-right: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.ceo-name p {
  text-transform: uppercase;
  margin-bottom: 0px !important;
  font-weight: 600;
}
.ceo-name p span {
  color: #4ea5d9;
}
.chart {
  padding: 30px;
}
.chart.res {
  display: none;
}
/* ========================================

BANNER STYLES

===========================================*/
.banner {
  background: url("https://images.pexels.com/photos/6765369/pexels-photo-6765369.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  height: 50vh;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}
.banner-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  color: #fff;
}
.banner-inner h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 50px !important;
}
.banner-inner p {
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
  color: #9d9d8f;
}

.banner-inner a {
  background: #4ea5d9;
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: capitalize;
}
/* =============================

FOOTER STYLES

================================*/
.foot-inner .left {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}
.foot-box h5 {
  color: #4ea5d9;
  text-transform: uppercase;
  font-size: 24px !important;
  font-weight: 600;
}
.foot-box a {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: #9d9d8f;
  margin-bottom: 10px;
}
.foot-box p {
  margin-bottom: 8px !important;
  color: #9d9d8f;
  text-transform: uppercase;
}
.foot-inner .up {
  display: flex;
  justify-content: space-between;
}
.tp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  border-bottom: 1px solid #aaaaaa;
  padding: 10px;
}
.bt {
  margin-top: 20px;
}
.bt img {
  width: 40px;
  margin-right: 10px;
  margin-top: 10px;
}
.down {
  text-align: center;
  color: #9d9d8f;
  margin-top: 30px;
  border-top: 1px solid #aaaaaa;
  padding: 20px;
}
.down p {
  margin-bottom: 0px !important;
}
.foot-inner {
  padding: 30px 10px;
}
.foot-social {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.foot-social-cont {
  height: 40px;
  width: 40px;
  color: #fff !important;
  background: rgba(117, 117, 117, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.foot-social-cont:hover {
  color: #4ea5d9;
}
/*================================

LATEST TRANSACTION

=================================*/
@keyframes scroll2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100px * 6));
  }
}
.transaction {
  margin: 40px 0px;
}
.transact-table {
  width: 100%;
  border-spacing: 20px;
  text-transform: capitalize;
  color: #fff;
  animation: scroll2 10s linear infinite;
}
.transact-table.admin {
  animation: none;
}
.table-container {
  height: 50vh;
  margin-bottom: 20px;
  overflow-y: scroll;
}
.popup {
  padding-top: 20px;
}
.table-container::-webkit-scrollbar {
  display: none;
}
.transact-table th {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: left;
}
.transact-table thead {
  margin-bottom: 10px;
  background: #0c0c0c;
  position: relative;
  z-index: 9;
}
.transact-table .table-complete {
  width: 70%;
  display: block;
  text-align: center;
  padding: 10px 0px;
  margin-top: 10px;
  border-radius: 4px;
}
.transact-table .table-complete.complete {
  background: #0ba026;
}
.transact-table .table-complete.pending {
  background: #4ea5d9;
}
.pop-up-recieve {
  background: #fff;
  text-transform: capitalize;
  text-align: center;
  border-radius: 4px;
}
.locate {
  text-transform: uppercase;
  font-weight: 700;
}
.locate-recieve {
  color: #4ea5d9;
  font-weight: 800;
}
.transaction-inner {
  position: relative;
}
.pop-up-recieve {
  position: absolute;
  top: 50%;
  right: 10%;
}
.pop-up-recieve p {
  margin-bottom: 0px !important;
  padding: 10px 40px;
}
.chartContainer {
  width: 100% !important;
  height: 500px !important;
  overflow: hidden;
}
.slap-txt {
  text-align: center;
  text-transform: capitalize;
  color: #aaa;
  margin-top: 8px;
  display: block;
}
.logo {
  display: flex;
  text-decoration: none;
  text-transform: capitalize;
  color: #4ea5d9;
}
.logo span {
  padding-top: 5px;
  margin-left: -20px;
  font-weight: 700;
  font-size: 25px;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}
.welcome {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  margin: 15px 0px !important;
}
.log-signup {
  background: #4ea5d9;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none;
  text-transform: capitalize;
}
.packing-top svg {
  height: 30px;
  color: #fff;
  margin: 20px 0px;
}
.img-1,
.img-2 {
  width: 250px;
  height: 250px;
}
.img-1 {
  width: 50px;
  height: 50px;
}
.img-2 {
  background: #fff;
}
.backing-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.packing-body p {
  margin: 20px 0px !important;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
}
.packing-body p span {
  font-size: 20px !important;
  color: #4ea5d9;
  font-weight: 700;
}
.packing-boc span {
  display: block;
  text-align: center;
  margin: 20px 0px;
  color: #fff;
  text-transform: uppercase;
}
.wall-add {
  font-size: 14px;
  background: #1d1d1d;
  padding: 10px;
  cursor: pointer;
}
.paxk h1 {
  margin: 30px 0px;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
}
.smalls {
  margin: 10px 0px;
}
.packbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pct-btn {
  padding: 5px 30px;
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  background: transparent;
  border: 1px solid #4ea5d9;
  color: #4ea5d9;
}
.admin-title {
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 30px;
}
.admin-title span {
  color: #4ea5d9;
}
.user-ad-box img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user-ad-box p {
  margin-bottom: 0px !important;
  text-align: left;
  font-size: 15px;
}
.user-ad-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 10px;
  background: #1d1d1d;
  color: #fff;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.user-extra {
  background: #1d1d1d;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  transition: 0.3s ease-in-out all;
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  color: #fff;
  border-radius: 10px;
}
.user-extra.active {
  height: auto;
  max-height: 9999px;
  transition: 0.5s ease-in-out all;
  padding: 20px;
}
.user-extra p {
  text-transform: capitalize;
  letter-spacing: 1px;
}
.user-extra p span {
  color: #4ea5d9;
  margin-left: 20px;
}
.all-user-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  min-height: 50vh;
  margin-bottom: 20px;
}
.all-user-box::-webkit-scrollbar {
  display: none;
}
.admin-user-box h4 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px !important;
}
.ad-search {
  display: flex;
  align-items: center;
  border: 1px solid #4ea5d9;
  padding: 10px;
  border-radius: 10px;
}
.ad-search input {
  background: none;
  border: none;
  color: #fff;
}
.ad-search input:focus {
  outline: none;
}
.ad-search svg {
  height: 20px;
  color: #fff;
}
.admin-user-head {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dash-user-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1d;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  padding: 20px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 10px;
}
.dash-user-box svg {
  height: 30px;
}
.dash-user-box p {
  margin-bottom: 5px !important;
}
.dash-user-box h3 {
  margin-bottom: 0px;
}
.dash-head {
  margin: 40px 0px !important;
  color: #fff;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dash-head p {
  margin-bottom: 0px;
}
.dash-head h3 {
  margin-bottom: 0px;
}
.dash-head span {
  color: #4ea5d9;
  font-weight: 700;
}
.dash-user-cont {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin-bottom: 30px;
}
.dash-user-text p {
  font-size: 12px;
}
.dash-icon {
  background: #4ea5d9;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dash-icon svg {
  height: 20px;
}

.user-account-inner-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.user-account-inner-top h4 {
  color: #4ea5d9;
  text-transform: capitalize;
}

.signal-fee {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4ea5d9;
}
.signal-fee svg {
  height: 20px;
}
.signal-fee p {
  margin-bottom: 0px;
  text-transform: capitalize;
}
.link-new {
  text-transform: capitalize;
  text-decoration: none;
  color: #4ea5d9;
  transition: ease-in-out 0.3s;
}
.link-new:hover {
  text-decoration: underline;
}
.depo-acc {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 40px;
  gap: 30px;
}
.depo-acc-links p {
  color: #4ea5d9;
  text-transform: capitalize;
  border: #1d1d1d 1px solid;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}
.depo-acc-links p:hover {
  background: #1d1d1d;
}
.invest-opts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.invest-opts div {
  color: #fff;
  text-align: center;
  border: 1px solid #4ea5d9;
  padding: 30px 0px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.invest-opts div:hover {
  background: #4ea5d9;
}
.invest-opts p {
  margin-bottom: 0;
}
.opts-select {
  background: #4ea5d9;
  font-weight: 600;
  transition: 0.3s ease-in-out;
}
.wown select {
  width: 100%;
  text-transform: capitalize;
  margin: 20px 0px;
  padding: 10px;
  background: none;
  border: 1px solid #4ea5d9;
  color: #4ea5d9;
  outline: none;
}
.wown input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #4ea5d9;
  color: #4ea5d9;
  background: none;
  margin: 14px 0px;
  padding: 10px;
}
.wown button {
  width: 100%;
  background: #4ea5d9;
  margin-bottom: 50px;
  border: none;
  color: #fff;
  text-transform: capitalize;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #4ea5d9;
  transition: 0.3s ease-out;
  margin-top: 40px;
}
.wown button:hover {
  background: transparent;
  color: #4ea5d9;
}
.wown h5 {
  color: #4ea5d9;
  text-transform: capitalize;
  font-weight: bold;
}
.tradingview-widget-container {
  height: 550px !important;
  margin-bottom: 50px !important;
  width: 100% !important;
}
.tradingview-widget-container__widget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  height: 100%;
}
#tradingview_09cb3 {
  height: 100%;
  width: 100%;
}
.sub-total {
  font-size: 25px;
  text-align: center;
  margin-bottom: 0;
  color: #4ea5d9;
}
.plain {
  color: #fff;
  text-align: center;
}
.payment-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  flex-direction: column;
  color: #fff;
  text-transform: capitalize;
}
.payment-div button{
  text-transform: capitalize;
  background: #4ea5d9;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.payment-div img{
  width: 290px;
  margin-bottom: 20px;
}
.stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}
.users-actions {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}
.credit,
.debit,
.bonus {
  color: #fff;
  border: none;
  padding: 8px 30px;
  text-transform: capitalize;
}
.deletediv {
  margin-top: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.credit {
  background: #0ba026;
}
.debit {
  background-color: red;
}
.bonus {
  background: #4ea5d9;
}
.btn-header-btn {
  width: 100%;
  background: none;
  color: #4ea5d9;
  border: 1px solid #4ea5d9;
  text-transform: capitalize;
  padding: 10px 0px;
}
.td-btn {
  padding-right: 15px;
}
.credit-box {
  display: flex;
  align-items: center;
}
.credit-box input {
  flex: 1;
  outline: none;
  padding: 6px 12px;
}
.bonus.p {
  background: rgb(100, 120, 12);
}
.user-warn{
  background: #4ea5d9;
  color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  font-weight: bold;
}
.user-warn p{
  margin-bottom: 0;
}
/*===============================

MEDIA QUERIES  

=================================*/
@media screen and (max-width: 1200px) {
  .tradingview-widget-container__widget {
    grid-template-columns: 1fr;
  }
  .depo-acc {
    grid-template-columns: 1fr;
  }
  .user-account-inner-top h4 {
    font-size: 16px;
  }
  .invest-opts {
    grid-template-columns: 1fr;
  }
  .td-btn {
    padding: 0px 15px;
  }
  .transact-table.admin {
    width: 200vw;
  }
  .dash-user-cont {
    grid-template-columns: 1fr;
  }
  .user-ad-box,
  .user-extra {
    width: 150vw;
  }
  .log-signup.adm {
    display: none;
  }
  .pack {
    padding: 15px;
  }
  .team-info h4 {
    font-size: 20px !important;
  }
  .bt {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }
  .foot-inner .up {
    flex-direction: column;
  }
  .banner-inner h2 {
    font-size: 35px !important;
    text-align: center;
  }
  .ceo {
    flex-direction: column;
    height: fit-content;
  }
  .chart {
    padding: 10px;
    margin-top: 80px;
  }
  .quote {
    padding: 10px !important;
  }
  .quote-text {
    margin: 10px;
  }
  .ceo-name {
    padding: 30px;
  }
  .experts .about-title {
    font-size: 35px !important;
  }
  .cal-body {
    flex-direction: column;
  }
  .btc {
    width: 100%;
    margin: 30px 0px;
  }
  .cal-body select {
    width: 55px !important;
  }
  .btc input {
    flex: 1;
  }
  .calculator-body {
    padding: 35px;
    height: fit-content;
    top: -50px;
    left: 0;
    right: 0;
  }
  .calculator {
    margin-bottom: 400px;
  }
  .equal {
    transform: rotate(180deg) !important;
  }
  .pack h5 {
    font-size: 20px !important;
  }
  .pack h1 {
    font-size: 30px !important;
  }
  .packages .about-title {
    font-size: 45px !important;
  }
  .login-box {
    width: 400px;
  }
  .overlay-close,
  .overlay iframe {
    padding: 20px;
  }
  .intro,
  .intro-inner {
    grid-template-columns: 1fr;
  }
  .vid {
    height: 300px;
  }
  .about-subtext {
    padding: 0px;
  }
  .main-img {
    width: 100%;
    height: 100%;
  }
  .main-about {
    flex-direction: column;
    padding: 30px;
  }
  .main-about-heading {
    text-align: center;
  }
  .about-tab {
    flex-direction: column;
  }
  .about-tab h3:nth-child(2),
  .about-tab h3:nth-child(3) {
    border-top: 1px solid #aaaaaa;
    border-left: none;
    padding: 20px 0px;
  }
  .btn-abt {
    width: 100%;
  }
  .slide-contain {
    overflow-x: hidden;
  }
  .slide-contain::-webkit-scrollbar {
    display: none;
  }
  .steps-inner {
    grid-template-columns: 1fr;
    margin-top: -30px;
    padding: 20px;
  }
  .minor {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .slide-1 h1,
  .slide-2 h1 {
    text-align: center;
  }
  .slide-1,
  .slide-2 {
    height: 460px;
  }
  .main-nav {
    position: sticky;
  }
  .bar {
    display: block;
    cursor: pointer;
  }
  .forlinks {
    position: fixed;
    top: 0;
    right: -100%;
    transition: ease-in-out 0.6s all;
    flex-direction: column;
    justify-content: unset;
    gap: 40px;
    background: #1d1d1d;
    height: 100vh;
    width: 50vw;
    z-index: 3;
  }
  .forlinks.active {
    flex-direction: column;
    justify-content: unset;
    gap: 40px;
    right: 0%;
    background: #1d1d1d;
    height: 100vh;
    width: 50vw;
  }
  .nav-links {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  .login-sign {
    flex-direction: column;
    padding: 20px;
  }
  .btn-login,
  .btn-signup {
    margin: 0px;
    margin-top: 25px;
    padding: 15px !important;
  }
  .forlinks .bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px !important;
    background: none;
  }
  .forlinks .bar svg {
    padding: 0px 20px;
  }
  .nav-inner {
    gap: 0px;
  }
  .nav-inner img {
    width: 120px;
    margin-top: -30px;
  }
  .live {
    overflow: scroll;
  }
  .live::-webkit-scrollbar {
    display: none;
  }
  .readings {
    width: 200vw;
    padding: 10px 20px;
  }
  .home-login-sign {
    display: flex;
  }
  .btn-login,
  .btn-signup {
    padding: 5px 0px !important;
    width: 400px;
    justify-content: center;
  }
  .home-login-sign a {
    border-radius: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .user-ad-box,
  .user-extra {
    width: 300vw;
  }
  .logo span {
    margin-left: 5px;
  }
  .main-nav {
    padding: 10px 0px;
    height: 80px;
  }
  .steps-inner {
    margin-top: -60px;
  }
  .slide-name p {
    font-weight: 400;
    font-size: 15px;
  }
  .curslide img {
    width: 15px;
    height: 15px;
  }
  .slide-text span:nth-child(1) {
    font-weight: 600;
  }
  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-400px * 3));
    }
  }
  .curslider {
    height: 100px;
  }
  .curslide {
    height: 100px;
    display: block;
    border-right: 1px solid #aaa;
  }
  .curslider-track {
    padding-top: 3px;
    padding-bottom: 20px;
    animation: scroll3 30s linear infinite;
  }
  .slide-text {
    display: block;
  }
  .slide-text span {
    display: block;
  }
  .slide-text .green,
  .slide-text .red {
    border: none;
  }
  .slide-1 h1,
  .slide-2 h1 {
    font-size: 30px !important;
  }
  .about-title {
    font-size: 35px !important;
  }
  .packages .about-title,
  .calculator-body .about-title {
    font-size: 35px !important;
  }
  .btc {
    flex-direction: column;
  }
  .btc input {
    margin-bottom: 15px;
  }
  .calculator {
    margin-bottom: 450px;
  }
  .transaction-inner .about-title {
    font-size: 25px !important;
  }
  .transact-table .table-complete {
    width: 100%;
  }
  .transact-table tbody tr td:nth-child(2) span {
    display: block;
    text-align: center;
  }
  .transact-table th {
    font-size: 17px;
  }
  .transact-table th:nth-child(2) {
    text-align: center;
  }
  .chart.res {
    display: block;
  }
  .chart {
    padding: 0px;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .pop-up-recieve {
    position: absolute;
    top: 30%;
    right: 10%;
  }
  .pop-up-recieve p {
    font-size: 14px;
    padding: 10px;
  }
  .ceo {
    margin-bottom: 20px;
  }
  .main-nav {
    background: #0c0c0c;
  }
  .login-sign {
    display: none;
  }
  .nav-inner {
    padding: 10px 0px;
  }
  .nav-inner img {
    width: 100px;
  }
  .bar svg {
    height: 30px;
  }
  .btn-login,
  .btn-signup {
    padding: 5px 0px !important;
    width: 50%;
    justify-content: center;
  }
  .home-login-sign {
    padding: 15px;
  }
  .home-login-sign a {
    border-radius: 0px !important;
  }
  .admin-user-head {
    flex-direction: column;
  }
  .admin-user-head h4 {
    margin-bottom: 15px !important;
  }
}
